<template>
  <validation-observer ref="newFormValidation">
    <b-form class="auth-login-form mt-2 px-2" @submit.prevent>
      <b-row class="d-flex align-items-center">
        <b-col md="8">
          <b-row>
            <b-col md="6">
              <h6 class="font-weight-bolder text-colorBlack">Name</h6>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="firstName">
                <validation-provider
                  #default="{ errors }"
                  name="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="firstName"
                    v-model="firstName"
                    :state="errors.length > 0 ? false : null"
                    name="firstName"
                    placeholder="First Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group></b-col
            >
          </b-row>
        </b-col>
        <b-col md="4">
          <b-form-group label-for="lastName">
            <validation-provider
              #default="{ errors }"
              name="lastName"
              rules="required"
            >
              <b-form-input
                id="lastName"
                v-model="lastName"
                :state="errors.length > 0 ? false : null"
                name="lastName"
                placeholder="Last Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <hr class="px-3" />
      <b-row class="d-flex align-items-center">
        <b-col md="4">
          <h6 class="font-weight-bolder text-colorBlack">Email address</h6>
        </b-col>
        <b-col md="4">
          <b-form-group label-for="email">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="email"
                :state="errors.length > 0 ? false : null"
                name="email"
                placeholder="Email Address"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <hr class="px-3" />
      <b-row class="d-flex align-items-center">
        <b-col md="4">
          <h6 class="font-weight-bolder text-colorBlack">Your photo</h6>
        </b-col>
        <b-col md="4">
          <!-- Add your photo field here -->
          <!-- Example: -->
          <input type="file" name="photo" id="photo" />
        </b-col>
      </b-row>
      <hr class="px-3" />
      <b-row class="d-flex align-items-center">
        <b-col md="4">
          <h6 class="font-weight-bolder text-colorBlack">Contact Number</h6>
        </b-col>
        <b-col md="4">
          <b-form-group label-for="contactNumber">
            <validation-provider
              #default="{ errors }"
              name="contactNumber"
              rules="required"
            >
              <b-form-input
                id="contactNumber"
                v-model="contactNumber"
                :state="errors.length > 0 ? false : null"
                name="contactNumber"
                placeholder="Contact Number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
            <!-- Buttons -->
      <hr class="px-3" />
      <div class="text-right pb-2">
        <b-button
          variant="colorGray"
          class="mr-1"
          rounded
          :to="{ name: 'Profile' }"
        >
          Cancel
        </b-button>
        <b-button
          type="submit"
          variant="colorBlue"
          rounded
          :to="{ name: 'Profile' }"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import { required } from "@validations";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      instituteName: "",
      department: "",
      class1: "",
      role: "",
      country: "",
      timezone: "",
      bio: "",
    };
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style></style>
