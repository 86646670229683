var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"newFormValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2 px-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"8"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v("Name")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false : null,"name":"firstName","placeholder":"First Name"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"lastName"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false : null,"name":"lastName","placeholder":"Last Name"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"px-3"}),_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v("Email address")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"name":"email","placeholder":"Email Address"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"px-3"}),_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v("Your photo")])]),_c('b-col',{attrs:{"md":"4"}},[_c('input',{attrs:{"type":"file","name":"photo","id":"photo"}})])],1),_c('hr',{staticClass:"px-3"}),_c('b-row',{staticClass:"d-flex align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h6',{staticClass:"font-weight-bolder text-colorBlack"},[_vm._v("Contact Number")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"contactNumber"}},[_c('validation-provider',{attrs:{"name":"contactNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contactNumber","state":errors.length > 0 ? false : null,"name":"contactNumber","placeholder":"Contact Number"},model:{value:(_vm.contactNumber),callback:function ($$v) {_vm.contactNumber=$$v},expression:"contactNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"px-3"}),_c('div',{staticClass:"text-right pb-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"colorGray","rounded":"","to":{ name: 'Profile' }}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"type":"submit","variant":"colorBlue","rounded":"","to":{ name: 'Profile' }}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }